.login-and-registration {
    flex: 1;
    color: white;
    background-color: #004400;
    box-shadow: 6px 6px 6px #6a5f1e66;
}
.login-and-registration button {
    border: solid #007600 1px;
    margin: 6px 0 12px 0;
    padding: 4px;
    color: white;
    box-shadow: 0px 3px 6px beige;
    min-width: 150px;
}
.login-and-registration a {
    color: white;
}
.login-and-registration table {
    flex-direction: column;
    margin: 0 auto;
}

.account-overview {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
}

.account-overview-item {
    color: white;
    /* background-color: #004400; */
    flex: 1;
    display: inline-block;
    max-width: 1000px;
    /* box-shadow: 6px 6px 6px #6a5f1e66; */
    padding: 0;
    margin: 0;
}

.account-overview-item button {
    /* min-width: auto; */
    /* width: 70%; */
    display: inline-block;
    padding: 13px 0 0 0;
    color: white;
    box-shadow: none;
    font: bold 16px Arial, sans-serif;
}
.account-overview-item button:hover {
    box-shadow: none;
}
.account-overview-item a {
    width: 70%;
    display: inline-block;
    margin: 0;
    padding: 12px 0 12px 0;
    color: white;
    font: bold 16px Arial, sans-serif;
}
.account-overview-item a:hover {
    transform: scale(1.2);
    transition: all 300ms;
}

.border-right {
    border-right: #f5f5dc44 solid 2px;
}

.icon {
    width: 20px;
    max-width: 100%;
    margin-bottom: 0.5em;
    margin: 0;
}

.text {
    margin-top: 0.5em;
    margin: 0;
}

@media only screen and (max-width: 767px) {
    .account-overview-item a {
        font: bold 10px Arial, sans-serif;
    }

    .account-overview-item button {
        font: bold 10px Arial, sans-serif;
    }
}
