* {
	margin: 0;
	padding: 0;
}

#slideshow {
	height: calc(60vw + 20px);
	position: relative;
	top: -4vw;
    user-select: none;
}

.entire-content-carrousel {
	margin: auto;
	perspective: 80vw;
	width: 25vw;
}

.content-carrousel {
    padding-top: 10vw;
	width: 100%;
	animation: rotar 25s infinite linear;
	transform-style: preserve-3d;
	position: absolute;
}

.content-carrousel:hover {
	animation-play-state: paused;
	cursor: pointer;
}

.content-carrousel figure {
	overflow: hidden;
}

.shadow {
    position: absolute;
    box-shadow: 0px 0px 20px 0px #000;
    border-radius: 1.5vw;
}

.content-carrousel img, .content-carrousel video
{
	width: 100%;
	/* height: 16.66666vw; */
}

.content-carrousel img:active, .content-carrousel video:active {
	transform: scale(1);
	transition: all 300ms;
}

.content-carrousel p {
    padding: 0;
    margin: 1vw 0 1.2vw 0;
    height: 3.4vw;
    font-size: 1.6vw;
    font-weight: bold;
}

.rotation-controller {
    box-shadow: 0px 0px 10px 0px #000;
	margin: 0 4vw 0 4vw;
	height: calc(18vw + 16px);
	border-radius: 20px;
	padding: 0 2vw 0 2vw;
}
@keyframes rotar {
	from {
		transform: rotateY(0deg);
	} to {
		transform: rotateY(360deg);
	}
}

.arrow-right {
	width: 15vw;
	height: 17vw;
}

@media (min-width: 1200px) {
	#slideshow {
		height: 710px;
		position: relative;
		top: -48px;
	}
	
	.entire-content-carrousel {
		perspective: 960px;
	}
	.entire-content-carrousel {
		width: 300px;
	}
	.content-carrousel {
		padding-top: 120px;
	}
	.content-carrousel p {
		margin: 14px 0 5px 0;
		height: 34px;
		font-size: 16px;
	}
	.rotation-controller {
		height: 208px;
	}
	
	.arrow-right {
		width: 180px;
		height: 180px;
	}
}
