.calendar {
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    margin: 0 auto;
}

.weekday-label-short {
    display: none;
} 
.weekday-label-full {
    display: inline-block;
}

.time-header {
    width: 180px;
    font-weight: normal;
    border-radius: 28px;
}
.time-header-selected {
    font-weight: bold;
}
.time-header-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.time-unit-left {
    font-weight: normal;
    max-width: 80px;
}
.time-unit-info {
}
.time-unit-calendar-week {
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
    box-shadow: 0px 2px 4px #6a5f1e33;
}
.time-unit-text {
    font-weight: normal;
    text-align: right;
    padding-right: 5px;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
    box-shadow: 0px 2px 4px #6a5f1e33;
}
.time-unit-booked {
    padding: 0;
    border: solid 2px #ff363600;
    background: linear-gradient(to right, #005100, #004400,  #005100);
    text-decoration: line-through;
    box-shadow: 0px 2px 4px #6a5f1e66;
    color: white;
}
.time-row .time-unit-booked {
    font-size: 0.7em;
    text-decoration: none;
}
.time-unit-booked div {
    text-decoration: line-through;
}
.time-unit-break {
    background: linear-gradient(to right, #0000ffee, #0000bbff,  #0000ffee);
    color: white;
}
.time-unit-break.with-comment {
    font-size: 0.7em;
    background: linear-gradient(to right, red, #0000ffee, #0000bbff,  #0000ffee,  red);
}
.time-unit-free {
    border: solid 2px #00440000;
    background: linear-gradient(0deg,#0000000f,#0000,#0000000f);
    cursor: pointer;
    box-shadow: 0px 2px 4px #6a5f1e66;
}
.time-unit-insufficient {
    border: solid 2px #ffb4b400;
    background: linear-gradient(to left, #018201, #007600,  #018201);
    color: white;
    box-shadow: 0px 2px 4px #6a5f1e66;
    text-decoration: line-through;
}
.time-unit-insufficient div {
    text-decoration: line-through;
}
.time-unit-selected {
    border-left: solid 2px #000000aa;
    border-right: solid 2px #000000aa;
    font-weight: bold;
}
.time-unit-selected-top {
    border-top: solid 2px #000000aa;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.time-unit-selected-bottom {
    border-bottom: solid 2px #000000aa;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.time-unit-text-selected {
    font-weight: bold;
}
.weekday-label-small {
    font-size: 14px;
}

.with-flex-break-start {
    border-bottom: solid 2px #004400;
}
.with-flex-break-end {
    border-top: solid 2px #004400;
}


.book-link-container {
    position: absolute;
    margin-top: -140px;
    left: calc(50% - 110px);
    transform: 'translate(-50%, -50%)';
    border-radius: 20px;
    background: linear-gradient(to right, #004400, #004400, #038a03,  #004400,  #004400);
    background-size: 200% 100%;
    animation: gradient-rotation 3s infinite linear;
    box-shadow: 0px 4px 8px #6a5f1e66;
}
.book-link-container a {
    display: inline-block;
    width: 230px;
    font-size: 0.9em;
    font-weight: bold;
    color: 004400;
    padding: 4px;
}
.book-link-container:hover {
    box-shadow: 0px 5px 10px #6a5f1ebb;
    cursor: pointer;
	transform: scale(1.2);
	transition: all 300ms;
}
.book-link-container:active {
  background-color: #004400;
  color: #ffffffff;
}

.book-spontaneous {
    cursor: default;
    position: absolute;
    margin-top: -140px;
    background-color: #004400;
    color: #ffffffff;
    width: 230px;
    padding: 10px;
    border-radius: 20px;
}
.book-spontaneous div {
    display: inline-block;
    font-size: 0.9em;
    font-weight: bold;
    color: 004400;
    padding: 10px;
    background-color: #ffffff;
    color: #004400;
    border-radius: 10px;
}

.book-link {
    padding: 5px;
    border-radius: 10px;
    margin: 10px;
    background-color: #fcfcec;
}

.calendar-max-months-info {
    margin: 20px 2px 40px 2px;
}

@media (max-width: 768px) {
    .weekday-label-short {
        display: inline-block;
    } 
    .weekday-label-full {
        display: none;
    }

    .time-row .time-unit-booked {
        font-size: 0.4em;
    }

    .time-unit-break.with-comment {
        font-size: 0.4em;
    }
}

@keyframes gradient-rotation {
    0% {
        background-position: 200% 0%;
    }
    30% {
        background-position: 150% 50%;
    }
    70% {
        background-position: 150% 50%;
    }
    100% {
        background-position: 0 0%;
    }
}
