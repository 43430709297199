.filter-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.filter-criteria {
    flex-direction: column;
}

.filter-label {
    font-weight: bold;
    text-align: left;
    flex-shrink: 0;
    padding: 0.2rem 0.2rem;
    font-size: 0.8em;
}
.filter-label-input {
    max-width: 4.0rem;
    font-size: 0.8em !important;
    padding: 0 !important;
    margin: 0 !important;
}

.filter-option {
    border-top: 2px solid #007600;
    border-bottom: 2px solid #007600;
    border-right: 2px solid #007600;
    flex: 1;
    padding: 0.1rem 0.1rem;
    cursor: pointer;
    text-align: center;
    transition: background-color 1s;
    box-shadow: 0px 5px 3px #00000060;
}

.filter-option-active {
    /* border: 2px solid #004400; */
    background-color: #004400;
    color: white;
    transition: box-shadow 6px 10px 10px #00000060;
}

.filter-label + .filter-option,
.filter-option:first-child {
    border-left: 2px solid #007600;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.filter-option:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.filter-option:hover {
    border-color: #003300;
}

@media only screen and (max-width: 767px) {
    .filter-option-customer {
        font-size: 0.6em;
    }

    .filter-label input {
        width: 5.0rem;
    }
}
