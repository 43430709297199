.processing-circle {
    display: inline-block;
    width: 50px;
    height: 50px;
    animation: rotate 2s linear infinite;
}

.processing-circle svg {
    width: 100%;
    height: 100%;
}

.processing-path {
    stroke: #004400; /* Change color as needed */
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}
