.journal-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 99%;
    background-color: #004400;
    color: white;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 4px 4px 10px black;
    margin-bottom: 20px;
}

.journal-item {
    flex: 1 1 380px;
    box-sizing: border-box;
    padding: 6px;
}

.journal-item textarea {
    width: 100%;
    min-height: 65px;
    padding: 2px;
    font-size: 1em;
    padding: 2px;
}

.journal-item-description {
    text-align: left;
    font-size: 0.7em;
    font-weight: bold;
}

.journal-header {
    width: 100%;
    text-align: left;
    padding-left: 10px;
}

.journal-readonly-description {
    text-align: left;
    width: 100%;
    padding: 2px;
    font-size: 1em;
}

.health-condition-container {
    border-radius: 20px;
    border: 2px solid #007600ff;
    padding: 6px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 4px;
    box-shadow: 4px 4px 10px black;
}

.health-prop {
    text-align: left;
    display: flex;
    flex-direction: column;
    flex: 1 1 120px;
    min-width: 60px;
}

.health-label {
    font-weight: bold;
    font-size: 0.8em;
    margin-bottom: 4px; /* Adds space below the label */
}

.health-value {
    font-size: 0.9em;
}
