.about-us-overview {
    display: flex;
    flex-wrap: wrap;
}

.about-us-item {
    color: white;
    flex: 1;
    display: inline-block;
    max-width: 1000px;
}
.about-us-item a {
    /* width: 70%; */
    display: inline-block;
    margin: 0;
    padding: 12px 0 12px 0;
    color: white;
    font: bold 16px Arial, sans-serif;
}
.about-us-item a:hover {
    transform: scale(1.2);
    transition: all 300ms;
}

.border-right {
    border-right: #f5f5dc44 solid 2px;
}

.about-us-item img, svg {
    height: 24px;
    max-width: 100%;
    margin: 0;
}

.text {
    margin-top: 0.5em;
    margin: 0;
}

@media only screen and (max-width: 767px) {
    .about-us-item a {
        font-size: 0.6em;
    }
}