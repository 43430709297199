.voucher-cover {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 50vh;
  background-image: url("./img/cover.jpg");
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  color: #13693f;
  z-index: 1;
}
.voucher-cover h1 {
  font-size: 2.5rem;
}
.voucher-cover img {
  position: absolute;
  z-index: 0;
  height: 75vh;
}

/* Surprise Section */
.surprise {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.surprise-left {
  padding:  0 2vw;
  display: flex;
  flex-direction: column;
  width: 65%;
}
.text-box {
  text-align: start;
}
h1.text-box {
  font-size: clamp(1.8rem, 2.5vw, 3rem);
  padding: 2vw 2vw 0 2vw;
}
h2.text-box,
.text-box h2 {
  font-size: clamp(1.4rem, 2vw, 2.6rem);
  font-weight: normal;
  padding-bottom: 2px;
}
.method-box {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
}

.method-list {
  display: flex;
  justify-content: space-between;
  gap: 0.1rem;
  width: 100%;
}

.method-list img {
  height: 10vw;
  max-height: 100px;
  width: auto;
}

.surprise-right {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
}
.surprise-right img {
  position: absolute;
  right: 0px;
  z-index: 0;
  width: 100%;
}

.voucher-form {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 4vw 0;
}
.voucher-form #present {
  z-index: 2;
  position: absolute;
  top: 30%;
  left: 2%;
}.voucher-form #present2 {
    z-index: 2;
    position: absolute;
    bottom: 20%;
    right: 2%;
  }
.voucher-form form {
  /* width: 50%; */
  width: clamp(380px, 70vw, 800px);
}

.voucher-form .slide {
  position: relative;
  z-index: 2;
  margin-top: 5rem;
  background-color: #ffffff;
  box-shadow: 4px 4px 8px 3px rgba(0, 0, 0, 0.1);
  display: grid;
  /* grid-template-columns: 1fr; */
  margin: 0;
}

.voucher-form h2,
.voucher-form h3 {
  color: #13693f;
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  padding: 5px 2vw;
}

.voucher-form label {
  color: #13693f;
  font-weight: bold;
  text-align: start;
  width: 100%;
  margin-bottom: 4px;
}

.voucher-form input,
.voucher-form select {
  width: 100%;
  padding: 15px;
  margin-bottom: 4px;
  border: 2px solid #13693f;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
}

.voucher-form button {
  padding: 15px;
  background-color: #13693f;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.3s ease;
	transform: scale(0.8);
}

.voucher-form button:hover {
  background-color: #0f5035;
	transform: scale(0.9);
}
