.absence-container {
    flex-direction: column;
    margin: 0 auto;
    max-width: 95%;
}

.absence-tabs {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    padding: 0;
    margin: 10px 3px 1px 1px;
    gap: clamp(1px, 1vw, 5px);
}
.absence-tab {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
    background-color: #00760033;
    cursor: pointer;
    box-shadow: 1px 2px 4px #6a5f1e66;
    font-size: clamp(0.8rem, 1.7vw, 1.4rem);
}
.absence-tab.active {
    box-shadow: inset 1px 4px 4px #00760033;
    font-weight: bold;
    cursor: inherit;
}

.absence-div {
    /* border: 2px solid #00760033; */
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
}
.absence-table {
    width: 100%;
}
.absence-table td {
    margin: 0;
    padding: 0;
    font-size: clamp(0.7rem, 1.6vw, 1.3rem);
}
.absence-table th {
    font-size: clamp(0.6rem, 1.5vw, 1.2rem);
    padding: 4px;
    background-color: #00760033;
}
.absence-table input,
.absence-table select,
.absence-table textarea {
    font-size: clamp(0.6rem, 1.5vw, 1.4rem);
}
.absence-table textarea {
    width: 96%;
}
.absence-table tr:nth-child(2n) {
    background-color: #00760033;
}
.absence-table tr:nth-child(2n + 1) {
    background-color: #00760011;
}

#hour_from {
    width: 55px;
}
#hour_to {
    width: 55px;
}

@media only screen and (max-width: 767px) {
    .absence-container {
        max-width: 100%;
    }

    .hide-if-tight {
        display: none;
    }
}