.language {
    display: flex;
    float: right;
}

.language a {
    color: white;
    font-weight: bold;
    min-height: 28px;
    margin: 5px;
    box-shadow: 0px 6px 8px #00000066;
    cursor: pointer;
    border-radius: 10px;
    font-size: 12px;
    margin: 15px 2vw 15px 2vw;
}
.language a:hover {
    box-shadow: 0px 8px 12px #00000099;
    transform: scale(1.2);
    transition: all 300ms;
}

.language img {
    height: 50px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.small-width {
    display: none;
} 
.full-width {
    display: inline-block;
}
@media (max-width: 768px) {
    .small-width {
        display: inline-block;
    } 
    .full-width {
        display: none;
    }
    .language img {
        height: 22px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    }
    .language a {
        border-radius: 5px;
    }

    .language a {
        margin: 6px 2vw 6px 2vw;
    }
}