.profile {
    flex-direction: column;
    margin: 0 auto;
    max-width: 90%;
}
.profile p {
    text-align: left;
    padding-left: 5px;
}

.profile table {
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
}
.profile-label {
    text-align: right;
    padding-right: 8px;
    font-size: 19px;
    font-weight: bold;
}
.profile-label-small {
    text-align: right;
    padding-right: 8px;
    font-size: 18px;
    font-weight: bold;
}

@media only screen and (max-width: 767px) {
    .profile {
        max-width: 100%;
    }
    .profile-label {
        font-size: 17px;
    }
    .profile-label-small {
        font-size: 16px;
    }
}
