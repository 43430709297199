.booking-table-base {
    flex-direction: column;
}

.booking-table {
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
    width: 100%;
}
.booking-table td {
    padding: 4px;
}
.booking-table th {
    padding: 4px;
    background-color: #00760033;
}
.booking-table tr:nth-child(2n) {
    background-color: #00760033;
}
.booking-table tr:nth-child(2n + 1) {
    background-color: #00760011;
}

button {
    /* box-shadow: none; */
    min-width: auto;
}
.cancel-button {
    /* box-shadow: none; */
    width: 80%;
    font: bold 16px Arial, sans-serif;
    color: red;
    padding: 2px;
    margin: 0;
}
.cancel-button-reverse {
    width: 80%;
    font: bold 22px Arial, sans-serif;
    color: #004400;
    padding: 2px;
    margin: 0;
}

@media only screen and (max-width: 767px) {
    .booking-table {
        font-size: 9px;
    }
    .arrangement_duration {
        display: none;
    }
}
