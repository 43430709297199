.check-item-container {
    display: flex;
    column-gap: 0.5rem;
    flex-wrap: wrap;
}

.check-item-container div {
    /* box-shadow: 0px 4px 8px #6a5f1e66; */
}

.check-item-controller button {
    width: 2rem;
    margin: 0 0.1rem;
    font-size: 1.0em;
    font-weight: bold;
}

.check-item-controller input {
    width: 3rem;
    font-size: 1.0em;
    font-weight: bold;
    color: #004400;
}

.check-summary {
    width: 100%;
    border: 2px solid #004400;
    border-radius: 15px;
    box-shadow: 0px 4px 8px #6a5f1e66;
    padding: 0.1rem;
}
.check-summary tr:nth-child(2n) {
    background-color: #00440020;
}

.check-summary {
    text-align: left;
}

@media only screen and (max-width: 767px) {
    .check-summary {
        font-size: 0.8em;
    }
}
