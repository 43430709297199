
select.generic-input {
    width: 480px;
}
input.generic-input {
    width: 468px;
}
textarea.generic-input {
    width: 468px;
}
.generic-input {
    /* border: solid 2px #00440066; */
    color: #004400;
    border: 2px solid #00440022;
    font-size: 21px;
    background-color: #00000000;
    box-shadow: 0px 4px 8px #6a5f1e66;
    padding: 5px;
    border-radius: 10px;
}
.generic-input-invalid {
    border: 2px solid #ff0000aa;
}

.generic-input-processing {
    background: linear-gradient(to left, #00000000, #00000000, #038a0344,  #00000000,  #00000000);
    background-size: 200% 100%;
    animation: gradient-rotation 1s infinite linear;
}
.generic-input-updated {
    border: 2px solid #00440022;
    animation: fadeOut 4s linear;
}
@keyframes fadeOut {
    0% {
        border: 2px solid #00440055;
    }
    50% {
        border: 2px solid #004400ff;
    }
    100% {
        border: 2px solid #00440022;
    }
}

@media only screen and (max-width: 767px) {
    select.generic-input {
        width: 280px;
    }
    input.generic-input {
        width: 268px;
    }
    textarea.generic-input {
        width: 268px;
    }
    .profile-label {
        font-size: 13px;
    }
    .generic-input {
        font-size: 14px;
    }
}
