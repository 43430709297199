.operation-plan {
    flex-direction: column;
    margin: 0 auto;
    max-width: 90%;
}

.operation-plan fieldset {
    box-shadow: 0px 5px 10px #6a5f1ebb;
    border: none;
    margin: 0 0 20px 0;
    border-radius: 10px;
}

@media only screen and (max-width: 767px) {
    .operation-plan {
        max-width: 100%;
    }

    .operation-plan {
        font-size: 11px;
    }
    .operation-plan input {
        font-size: 11px;
    }
}
