.voucher-details-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: flex-start;
    text-align: left;
    padding-bottom: 8px;
}

.prop-and-value {
    display: flex;
    flex-direction: column;
    flex: 1 1 calc(10% - 10px);
    min-width: 150px;
}

.label {
    font-weight: bold;
    font-size: 0.8rem;
}

.value {
    font-size: 1rem;
}
