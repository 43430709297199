/****** Common style *************/
body, html {
  color: #004400;
  background-color: beige;
  margin: 0;
  padding: 0;
  font-size: 18px;
  background-image: url('img/background1.jpg');
  background-size: 100% auto;
  background-attachment: fixed;
  font: normal 18px Arial, sans-serif;
  max-width: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

a {
  color: #004400;
  text-decoration: none;
  font: normal 18px Arial, sans-serif;
}
a.underline {
  text-decoration: underline;
}

h1 {
  font: bold 28px Arial, sans-serif;
  padding: 15px 0 8px 0;
}
h2 {
  font: bold 25px Arial, sans-serif;
  padding: 12px 0 6px 0;
}
h3 {
  font: bold 22px Arial, sans-serif;
  padding: 10px 0 5px 0;
}
h4 {
  font: bold 18px Arial, sans-serif;
  padding: 5px 0 3px 0;
}

li {
  margin-left: 30px;
  list-style-type: disc;
  text-align: left;
}

input select textarea button {
  font: bold 14px Arial, sans-serif;
}

button {
  border: none;
  color: #004400;
  font-weight: bold;
  background-color: #00000000;
  border-radius: 8px;
  padding: 5px;
  min-width: 160px;
  box-shadow: 0px 4px 8px #6a5f1e66;
  font: bold 14px Arial, sans-serif;
}

button:hover {
  box-shadow: 0px 5px 10px #6a5f1ebb;
  cursor: pointer;
	transform: scale(1.2);
	transition: all 300ms;
}
button:active {
  background-color: #004400;
  color: #ffffffff;
}

.link-button {
  display: inline-block;
  border: none;
  color: #004400;
  font-weight: bold;
  background-color: #00000000;
  border-radius: 8px;
  padding: 5px;
  box-shadow: 0px 4px 8px #6a5f1e66;
  font: bold 14px Arial, sans-serif;
}
.link-button:hover {
  box-shadow: 0px 5px 10px #6a5f1ebb;
  cursor: pointer;
	transform: scale(1.2);
	transition: all 300ms;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}
.bold {
  font-weight: bold;
}
.inline {
  display: inline;
}

.smaller-font {
  font-size: 0.8em;
}

/****** Transition *************/

.transition-group {
  padding: 0;
  margin: 0;
  position: relative;
}

.left-enter {
  transform: translateX(-100%);
}
.left-enter-active {
  transform: translateX(0);
  transition: transform 1000ms ease-in-out;
}
.left-exit {
  transform: translateX(100%);
  /* transform: translateX(0); */
}

/* .left-exit-active {
  display: none;
  transform: translateX(100%);
  transition: transform 1000ms ease-in-out;
} */


/*******************/
header {
  display: flex;
  flex-direction: column;
  min-height: 70px;
  /* border: solid black 1px; */
}

.main {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.main-header {
  display: flex;
  flex-direction: row;
  float: left;
  padding: 0;
  animation: logo-animation 5s forwards;
  user-select: none;
}
.Logo-Header {
  flex: 6;
  display: flex;
  float: left;
}
@keyframes logo-animation {
  0% {
    height: calc(16vw + 65px);
  }
  25% {
    height: calc(16vw + 65px);
  }
  100% {
    height: calc(8vw + 50px);
  }
}

.menu-content {
  z-index: 10;
  flex: 1;
  margin: 0;
  padding: 0;
}
.menu-content p {
  font-weight: bold;
  font-size: 12px;
}
.menu-content a {
  cursor: pointer;
	transition: all 300ms;
}

.menu-content-button {
  box-shadow: none;
  padding: 8px;
  display: inline-block;
}
.menu-content-button:hover {
	transform: scale(1.2);
	transition: all 300ms;
  box-shadow: none;
}

.menu-content-active {
  background-color: #004400;
}

.menu-content-active p {
  color: white;
}

.app-logo {
  text-align: left;
}
.app-logo img {
  max-height: 95%;
  max-width: 95%;
  padding: 0.2vw;
  /* background-color: #ffffff99; */
}

.menu-icon {
  width: 28px;
  margin: 5px;
}

.menu-content-active .img-passive {
  display: none;
}
.menu-content-active .img-active {
  display: inline-block;
}
.menu-content-passive .img-passive {
  display: inline-block;
}
.menu-content-passive .img-active {
  display: none;
}

.login {
  padding-left: 2px;
  display: flex;
  flex-direction: column;
  flex: 1;  
}

.therapies {
  display: flex;
  flex-flow: row wrap;
  flex-wrap: wrap;
  margin-left: 5px;
}
.therapy {
  display: inline-block;
  flex: 1;
  border-radius: 10px;
  max-width: 190px;
  min-height: 180px;
  border: solid 1px #6a5f1e66;
  box-shadow: 0px 4px 8px #6a5f1e66;
  margin: 0px;
  margin: 5px;
}
.therapy-image {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
}
.therapy-label {
  text-align: center;
}

.google-map {
  display: inline-block;
  margin: 5px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px #6a5f1e66;
  border-radius: 10px;
  width: 100%;
  height: 60vw;
  min-height: 500px;
}

.menu-extension {
  color: white;
  background-color: #004400;
  box-shadow: 6px 6px 6px #6a5f1e66;
  z-index: 10;
}

@keyframes blink {
  20% { opacity: 1; }
  50% { opacity: 0; }
  80% { opacity: 1; }
}

.environment-info {
  font-size: 50px;
  font-weight: bold;
  position: absolute;
  top: 20px;
  left: 100px;
  color: #bdbd333d;
  animation: blink 1s linear infinite;
  user-select: none;
}

.important-message {
  animation: blinkRed 4s infinite;
}
@keyframes blinkRed {
  0%, 30%, 100% {
      color: inherit;
  }
  10%, 14% {
      color: #ff4444; /* Blink in red */
  }
}

@media only screen and (max-width: 767px) {
  body, html {
    font-size: 17px;
  }

  h1 {
    font-size: 1.4em;
    padding: 12px 0 6px 0;
  }
  h2 {
    font-size: 1.3em;
    padding: 10px 0 5px 0;
  }
  h3 {
    font-size: 1.2em;
    padding: 8px 0 4px 0;
  }
  h4 {
    font-size: 1.1em;
    padding: 4px 0 2px 0;
  }

  button {
    /* border-radius: 8px; */
    padding: 4px;
    min-width: 160px;
  }

  .link-button {
    padding: 4px;
    font-size: 1.2em;
  }

  .menu-content p {
    font-size: 8px;
  }
  .menu-content-active p {
    font-size: 8px;
  }
  
  .environment-info {
    top: 20px;
    font-size: 20px;
    left: 70px;
  }
}