.therapy-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.therapy-video {
    width: 100%;
    max-width: 800px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px #6a5f1e66;
}

.duration-container {
    display: flex;
    max-width: 800px;
    align-items: center;
    justify-content: center;
}
.duration-container a {
    border-radius: 10px;
    box-shadow: 0px 4px 8px #6a5f1e66;
    padding: 10px;
    margin: 2vw;
    width: 24vw;
}
.duration-container a:nth-child(1) {
    margin-left: 0;
}
.duration-container a:nth-last-child() {
    margin-right: 0;
}

.duration-container a:hover {
    transform: scale(1.2);
    transition: all 300ms;
    box-shadow: 0px 5px 10px #6a5f1e66;
}
.duration-container a:active {
    transform: scale(1);
    transition: all 300ms;
}
.duration-container img {
    max-width: 50px;
}

.therapy-description {
    padding: 5px 0 35px 0;
    display: flex;
    justify-content: center;
}
.therapy-description p {
    padding: 5px;
    max-width: 800px;
    flex: 1;
    text-align: left;
}

.booking-text {
    font-weight: bold;
}