.cancel-dialogue {
    background-color: #f8f8f0;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px #6a5f1ebb;
    color: #004400;
    padding: 15px;
    margin: 5px 0 10px 0;
}
.cancel-dialogue fieldset {
    border: none;
    box-shadow: 0px 2px 6px #6a5f1ebb;
    padding: 8px;
    margin: 10px 0 10px 0;
}
.cancel-dialogue textarea {
    background-color: #f8f8f0;
    font: normal 18px Arial, sans-serif;
    background-color: none;
    border: none;
}
.cancel-dialogue legend {
    font-weight: bold;
    font-size: 12px;
}
.cancel-dialogue a {
    text-decoration: underline;
}

.canceling-text-area {
    width: 100%;
}

.canceling-generator-input span {
    padding: 0 20px 0 0;
}
.canceling-generator-input label {
    padding: 0 0 0 2px;
}

@media only screen and (max-width: 767px) {
    .cancel-dialogue {
        padding: 5px;
    }
    .cancel-dialogue textarea {
        font: normal 14px Arial, sans-serif;
    }
}