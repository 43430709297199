.bookingList {
    flex-direction: column;
    padding: 0 0.5vw 0 0;
}

.booking-list-warning {
    color: red;
    padding: 4px;
}
.booking-list-info {
    padding: 4px;
}
