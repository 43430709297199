.payment-table {
    margin-top: 10px;
    /* border-radius: 10px;
    box-shadow: 0px 5px 10px #6a5f1ebb; */
    /* color: #004400; */
}

.payment-table tr:nth-child(2n) {
    background-color: #00760033;
}
.payment-table tr:nth-child(2n + 1) {
    background-color: #00760011;
}

.payment-table th {
    font-size: 14px;
}

#percent {
    width: 60px;
}
#amount {
    width: 80px;
}

.payment-table input {
    font-size: 18px;
}
.payment-table select {
    font-size: 18px;
}
.payment-table textarea {
    font-size: 18px;
}

.payment-button {
    margin-left: 18px;
}

.cashback-button {
    margin: 4px;
}

.payment-percent {
    min-width: 60px;
}

@media only screen and (max-width: 767px) {
    #amount {
        width: 50px;
    }
    #percent {
        width: 38px;
    }
    #transactionText {
        width: 100px;
    }

    .payment-table th {
        font-size: 0.6em;
    }

    .payment-table td {
        font-size: 0.7em;
    }

    .payment-table input {
        font-size: 13px;
    }
    .payment-table select {
        font-size: 13px;
    }
    .payment-table textarea {
        font-size: 13px;
    }
}
