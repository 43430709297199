.time-weekplan-header-input {
    box-shadow: 0px 2px 2px #6a5f1ebb;
    border: none;
    font-weight: bold;
    padding: 5px;
    font-size: 16px;
    margin-left: 40px;
    width: 400px;
    border-radius: 10px;
}

.add-timeFrame-dialogue {
    padding: 10px;
}
.add-timeFrame-dialogue input {
    width: 50px;
}
.add-timeFrame-dialogue button {
    margin-left: 15px;
}

.time-weekplan-table {
    width: 100%;
}
.time-weekplan-table td {
    padding: 4px;
}
.time-weekplan-table th {
    padding: 4px;
    background-color: #00760033;
}
.time-weekplan-table tr:nth-child(2n) {
    background-color: #00760033;
}
.time-weekplan-table tr:nth-child(2n + 1) {
    background-color: #00760011;
}
.time-weekplan-table button {
    height: 25px;
    padding: 0;
    width: 60px;
    margin: 0 0 0 5px;
}

.time-weekplan-total {
    font-weight: bold;
}

/* .timeFrame-action {
    margin-left: 10px;
    height: 30px;
} */

.time-weekplan-add {
    margin: 15px 0 15px 0;
}

.weekplan-remove-button {
    margin-left: 8px;
    background-color: white;
}

.time-frame-container {
    display: flex;
    flex-direction: row;
}
.time-frame-text {
    flex: 5;
    text-align: center;
    /* border-top: dotted black 1px; */
}
/* .timeFrame-action-container {
    flex: 1;
    text-align: right;
} */

@media only screen and (max-width: 767px) {
    .time-weekplan-header-input {
        margin-left: 5px;
        width: 300px;
    }

    .time-weekplan-table button {
        height: 20px;
    }
}
