.booking-base {
    width: 100%;
    padding: 0;
    margin: 0;
}

.booking-inputs {
    width: 100%;
    padding: 0 0 10px 0;
    margin: 10px 0 0 0;
}
.booking-inputs table {
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
}
.booking-inputs td:nth-child(1) {
    text-align: right;
    padding-right: 20px;
}
.booking-inputs td:nth-child(2) {
    text-align: left;
}

.booking-inputs ul {
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
    max-width: 1000px;
}
.booking-authenticate {
    display: flex;
    flex-wrap: wrap;
}

.booking-comment-textarea {
    width: 100%;
    max-width: 800px;
    min-height: 60px;
    border-radius: 10px;
    margin: 6px;
    padding: 8px;
    border: none;
    box-shadow: 0px 4px 8px #6a5f1e66;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
}
