.form-group {
    display: flex;
    flex-direction: column;
    flex: 1 1 160px; /* Allows two items per row on larger screens */
    margin-bottom: 0.6em;
}

.form-group label {
    text-align: left;
    font-weight: bold;
    font-size: 0.8em;
    /* margin-bottom: 0.3em; */
}

.form-group input,
.form-group select {
    width: 100%;
    font-size: 1em;
    padding: 0.1em;
}

.form-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    padding: 1em;
}
