.container {
    display: flex;
    flex-direction: row;
    height: 60vh;
    user-select: none;
}

.main-content {
    flex-grow: 1; /* Main content takes remaining space */
}

.drawing-board {
    width: 100%;
    box-shadow: 0px 5px 10px #6a5f1ebb;
    margin: 5px;
    max-height: 1200px;
    max-width: 1200px;
}

.toolBox {
    /* display: flex;
    flex-direction: column; */
    text-align: left;
    display: flex;
    flex-flow: row wrap;
}

.toolBox fieldset {
    /* margin: 0 10px 20px 10px; */
    flex: 1;
    display: inline-block;
    box-shadow: 0px 5px 10px #6a5f1ebb;
    border: none;
    padding: 0 5px 10px 5px;
    margin: 5px;
}
.toolBox fieldset legend {
    font-weight: bold;
    font-size: 14px;
}
.pencilButtons {
    max-width: 200px;
}
.colorButtons {
    max-width: 450px;
}
.pencil-small {
    font-size: 9px;
    height: 36px;
}
.pencil-medium {
    font-size: 13px;
    height: 45px;
}
.pencil-large {
    font-size: 16px;
    height: 60px;
}

.pencileButton {
    border-radius: 30px;
    box-shadow: 5px 5px 8px #6a5f1e66;
    border: 4px solid #00000000;
    margin-right: 16px;
    padding: 2px;
}
.pencileButtonActive {
    border: 4px solid #000000ff;
}

.colorButton {
    box-shadow: 5px 5px 8px #6a5f1e66;
    height: 35px;
    min-width: 70px;
    margin: 4px 8px 0  4px;
    padding: 0 5px 0 5px;
}
.button-color-red {
    background-color: #ff0000;
    color: white;
}
.button-color-green {
    background-color: #00ff00;
    color: white;
}
.button-color-blue {
    background-color: #0000ff;
    color: white;
}
.button-color-yellow {
    background-color: #eeee00;
    color: black;
}
.button-color-black {
    background-color: #000;
    color: white;
}
.colorButtonActive {
    border: 4px solid #000000ff;
}
.button-color-black.colorButtonActive  {
    border: 4px solid white;
}

.modifyButtons button {
    box-shadow: 5px 5px 8px #6a5f1e66;
    height: 35px;
    margin: 14px 8px 0  8px;
    padding: 0 5px 0 5px;
}
.modifyModActive {
    border: 4px solid #000000ff;
}

.backgroun-buttons {
    font-size: 12px;
}
.backgroun-buttons th {
    font-weight: normal;
    min-width: 3.5vw;
}
.backgroun-buttons td {
}
.back-image-desc {
    padding-right: 4px;
    text-align: right;
}

.back-button {
    height: 15px;
    padding: 0;
    min-width: 35px;
}

.background-selected {
    padding-left: 15px;
}

/* Media query for switching layout based on available space */
@media (max-width: 1500px) {
    .container {
        flex-direction: column;
    }

    .main-content {
        order: 2; /* Reorder main content below */
    }

    .toolBox {
        order: 1; /* Reorder legend above */
        width: 100%; /* Full width */
    }
}
@media (min-width: 1500px) {
    .toolBox {
        max-width: 200px;
        display: flex;
        flex-direction: column;
    }
}


@media only screen and (max-width: 767px) {
    .pencil-small {
        font-size: 7;
        height: 35px;
    }
    .pencil-medium {
        font-size: 9;
        height: 45px;
    }
    .pencil-large {
        font-size: 12;
        height: 50px;
    }
    
    .colorButton {
        font-size: 10px;
        box-shadow: 5px 5px 8px #6a5f1e66;
        border-radius: 20px;
        height: 20px;
        margin: 0 4px 3px 4px;
        min-width: 45px;
    }

    .modifyButtons button {
        /* box-shadow: 5px 5px 8px #6a5f1e66; */
        height: 25px;
        font-size: 9px;
        margin: 8px 6px 0  6px;
        /* padding: 0 5px 0 5px; */
    }
}