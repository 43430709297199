.therapists {
    display: flex;
    flex-wrap: wrap;
    /* align-items: flex-start; */
}

.therapists-item {
    flex: 1;
    min-width: 300px;
    margin: 10px;
    overflow: hidden;
}

.therapists-item-big {
    min-width: 200px;
    max-width: 767px;
    max-width: 100%;
    margin: 10px;
    overflow: hidden;
}

.therapists p {
    text-align: left;
    margin-top: 0;
}

.therapists-item-big p {
    margin-bottom: 10px;
}
