.userList {
    width: 100%;
}

.user {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    padding: 4px 4px 8px 4px;
}

.userProp0 {
    background-color: #00440030;
}
.userProp1 {
    /* background-color: #00440010; */
}

.userProperty {
    display: inline-block;
    padding: 2px;
    margin: 4px 8px;
    text-align: left;
    box-shadow: 0px 1px 1px #6a5f1e33;
}

.userProperty-label {
    font-weight: bold;
    font-size: 0.8em;
}

.link {
    font-weight: bold;
    margin: 10px;
    padding: 5px;
    box-shadow: 0px 6px 8px #00000066;
    cursor: pointer;
    border-radius: 10px;
    font-size: 12px;
}
.link:hover {
    box-shadow: 0px 5px 10px #6a5f1ebb;
	transform: scale(1.2);
	transition: all 300ms;
}

.space-right {
    margin-right: 20px;
}

.profile-creation_date {
    min-width: 200px;
}

.client-list-head {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.client-list-head input {
    width: 140px;
}

.client-new-form-empty-buttons a {
    margin: 0 6px;
}

.client-sort-order {
    max-width: 140px;
    margin-left: 5%;
}
