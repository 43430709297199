.about-me-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.about-me-item {
    align-items: center;
    text-align: center;
    padding: 10px;
}

.about-me-text {
    max-width: 800px;
    text-align: center;
}

.portrait {
    width: 100%;
    max-width: 800px;
}
