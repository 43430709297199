.therapist-plan {
    box-shadow: 0px 5px 10px #6a5f1ebb;
    border: none;
    margin: 0 0 20px 0;
    border-radius: 10px;
}

#sequence {
    width: 60px;
}

.operation-plan table {
    width: 100%;
}

@media only screen and (max-width: 767px) {
    #sequence {
        width: 40px;
    }
    #startDate {
        width: 85px;
    }
}