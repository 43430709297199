.cash-transaction-controller {
    border: 2px solid #004400;
    border-radius: 15px;
    padding: 0.4rem;
    box-shadow: 0px 4px 8px #6a5f1e66;
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    user-select: none;
    gap: 1rem;
}

.cash-transaction-controller-item {
    text-align: left;
}

.transaction-comments {
    width: 15rem;
}
.all-figures-in-chf {
    width: 5rem;
}

.cash-label {
    font-size: 0.7em;
    font-weight: bold;
}

@media only screen and (max-width: 767px) {
    .cash-label {
        font-size: 0.6em;
    }
}
