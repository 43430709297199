.booking-group {
    /* margin: 0 auto; */
    width: 100%;
    table-layout: auto;
}

.daily-bookings {
    border-radius: 20px;
    border: 2px solid #007600ff;
    margin-bottom: 10px;
    box-shadow: 4px 6px 6px #00000060;
}

.day-hader {
    margin-left: 10%;
    border-radius: 20px;
    border: 2px solid #00760050;
    padding: 0 8px 0 8px;
    background-color: #004400;
    color: #ffffffff;
    text-align: left;
}
