.accounting-table {
    border: 2px solid #004400;
    border-radius: 15px;
    box-shadow: 0px 4px 8px #6a5f1e66;
    width: 100%;
}

.accounting-table th {
    text-align: center;
    font-size: 0.7em;
    padding: 0 4px 0 4px;
    background-color: #00440030;
}

.accounting-table tr {
    text-align: left;
}
.accounting-table tr:nth-child(2n) {
    background-color: #00440020;
}

.accounting-table td {
    font-size: 0.9em;
    padding: 0 4px 0 4px;
}

.accounting-context {
    font-size: 0.8em;
}

.cash-checking td {
    background-color: #004400;
    color: white;
}
.cash-checking button {
    color: white;
    box-shadow: 0px 2px 4px white;
}

@media only screen and (max-width: 767px) {
    .accounting-context {
        font-size: 0.7em;
    }

    .accounting-context {
        font-size: 0.7em;
    }
}