.date-picker-container {
    text-align: left;
}

.date-picker-container div {
    text-align: left;
}

.date-picker-container input {
    width: 50px;
    padding: 4px;
    border: none;
    border-radius: 4px;
}

.date-input-day {
    width: 40px !important;
}
.date-input-month {
    width: 40px !important;
}
.date-input-year {
    width: 60px !important;
}

.popup {
    margin-top: 2px; /* Distance from the input */
    background: white; /* Background color */
    border: 1px solid #ccc; /* Optional border */
    border-radius: 4px; /* Rounded corners */
    padding: 10px; /* Padding for the pop-up */
    position: absolute; /* Positioning as needed */
    z-index: 1000; /* Ensure it appears above other elements */
    display: grid; /* Use grid layout */
}

.day-popup {
    grid-template-columns: repeat(10, 1fr); /* 5 columns for days and months */
}
.day-popup div {
    border: 1px solid #ccc;
}

.month-popup {
    grid-template-columns: repeat(3, 1fr); /* 5 columns for days and months */
}
.month-popup div {
    border: 1px solid #ccc;
}

.popup-item {
    padding: 0.2rem; /* Padding for each item */
    text-align: center; /* Center text */
    cursor: pointer; /* Pointer on hover */
    transition: background-color 0.2s; /* Smooth transition */
}

.popup-item:hover {
    background-color: #f0f0f0; /* Hover effect */
}

@media only screen and (max-width: 767px) {
    .popup {
        right: 0;
    }

    .popup {
        display: none;
    }
}
