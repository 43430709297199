.booking-item-container {
    border-bottom: 2px solid #007600ff;
}
.booking-item-container:last-child {
    border-bottom: none;
}

.booking-item {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 2px;
    gap: 2px;
}
.booking-time {
    flex: 0.1 2 90px;
}
.booking-comments {
    flex: 8 8 200px;
}
.booking-therapy {
    flex: 6 6 100px;
}
.booking-arrangement {
    flex: 6 6 60px;
}
.booking-creation {
    flex: 6 6 120px;
}
.booking-buttons {
    flex: 20 20 400px;
    min-width: 200px;
}

.booking-creation fieldset,
.booking-comments fieldset {
    margin-right: 10px;
    padding: 4px;
    border: none;
}
.booking-creation legend,
.booking-comments legend {
    font-weight: bold;
    font-size: 0.6em;
    margin-left: 8px;
}

.booking-popup {
    padding: 8px;
}

.booking-item-canceled {
    /* color: beige; */
    /* background: linear-gradient(to top, #00440000, #004400b0, #004400c0, #004400d0, #004400c0, #004400b0,  #00440000); */
    background: linear-gradient(to top, #00000000, #00000040, #00000050, #00000040,  #00000000);
}
.booking-item-canceled .link-button {
    background-color: #f7f7ed90;
}
.booking-item-canceled button {
    background-color: #f7f7ed90;
}
.booking-comments.appointment-canceled fieldset {
    background: linear-gradient(to top, #00440000, #00440030, #00440000);
}

.appointment-canceled {
    text-decoration: line-through;
}
.appointment-canceled span {
    text-decoration: line-through;
}

.therapy-ongoing {
    font-weight: bold;
}
.booking-time.therapy-ongoing {
    border-radius: 5px;
    color: white;
    background-color: #004400ff;
}

.booking-list-link {
    margin: 2px 8px 2px 8px;
}

@media only screen and (max-width: 767px) {
    .booking-item {
        font-size: 0.8em;
    }
    
    button {
        font-size: 0.8em;
    }
    .link-button {
        font-size: 0.8em;
    }
}
