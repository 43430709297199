* {
	margin: 0;
	padding: 0;
}

.slideshow-container {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    min-height: 100;
}

.slideshow-text {
    order: 1;
    max-width: 1100px;
    padding: 0 5px 0 5px;
}

.slideshow-wheel {
    order: 2;
}

.wheel-link:hover {
	transform: scale(1.1);
	transition: all 600ms;
}

.wheel-link:hover video, .wheel-link:hover img {
	transform: scale(1.1);
	transition: all 300ms;
}

@media (min-width: 1200px) {
	.slideshow-container {
		margin: 1px auto;
	}
}

@media (min-width: 1600px) {
    .slideshow-container {
        flex-direction: row;
        max-width: none;
    }
  
    .slideshow-text {
        order: 1;
        width: 400px;
        margin: auto;
    }
  
    .slideshow-wheel {
        order: 2;
        width: 1200px;
    }
}
