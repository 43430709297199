.time-unit-container {
    margin: 6px;
}

.time-unit-container div {
    display: flex;
    width: 100%;
}

.time-unit {
    flex: 1;
    background-color: #00000080;
    height: 12px;
    margin: 1px;
    border-top: dotted black 1px;
    border-bottom: dotted black 1px;
    font-size: 10px;
    text-align: center;
}

.flex-break {
    background-color: #00440080;
}

.fix-break {
    background-color: #00000000;
}

@media only screen and (max-width: 767px) {
    .time-unit p {
        display: none;
    }
}
