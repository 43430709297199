.therapist-overview {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr)); /* Use auto-fill instead of auto-fit */
    gap: 2px 5px; /* Space between grid items */
    margin: 10px;
}

.therapist-overview-item {
    text-align: center; /* Center align text inside each item */
    padding: 1px;
    box-sizing: border-box;
    transition: transform 300ms; /* Ensure smooth scaling transition */
}

.therapist-overview-item a {
    color: white;
}

.therapist-overview-item:hover {
    transform: scale(1.2); /* Add a scaling effect on hover */
}

@media only screen and (max-width: 767px) {
    .therapist-overview {
        margin: 2px;
    }
    .therapist-overview-item a {
        font-size: 0.85em;
    }
}
