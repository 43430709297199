.year-table {
    border-collapse: collapse; /* Collapse borders */
}

.decade-label {
    font-weight: bold;
    text-align: left; /* Align text to the left */
    color: #555;
    padding: 0.4rem;
}

.nested-year-table {
    border-collapse: collapse; /* Collapse borders */
}

.nested-year-table td {
    text-align: center; /* Center text in year cells */
    cursor: pointer;
    padding: 0.5rem; /* Add some padding */
    border: 1px solid #ccc; /* Border around cells */
}

.popup-item:hover {
    background-color: #e9e9e9; /* Hover effect */
}
