.voucher-table {
    border: 2px solid #004400;
    border-radius: 15px;
    box-shadow: 0px 4px 8px #6a5f1e66;
    width: 100%;
}

.voucher-table th {
    text-align: left;
    font-size: clamp(0.6rem, 1.8vw, 0.8rem);
    padding: 0 4px 0 4px;
    background-color: #00440030;
}

.voucher-manage-row {
    text-align: left;
    cursor: pointer;
}
.voucher-manage-row:nth-child(2n) {
    background-color: #00440020;
}

.voucher-table td {
    font-size: clamp(0.7rem, 2vw, 1.0rem);
    padding: 0 4px 0 4px;
}

.selected-voucher, 
.selected-voucher:nth-child(2n){
    color: white;
    background-color: #004400;
    cursor: default;
}

.selected-voucher a {
    color: white;
}
.selected-voucher a:hover {
    color: #004400;
}

.voucher-manage-row:hover {
    background-color: #aaa;
}

.voucher-code-selection {
    width: 210px;
}

.selected-voucher-box {
    border: 2px solid #004400;
    border-radius: 15px;
    box-shadow: 0px 4px 8px #6a5f1e66;
    padding: 4px;
}
